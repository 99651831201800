import React, { useEffect, useState } from 'react';
import { Spinner } from '../common';
import { Button, Grid, GridCell, Heading, Icon, Main, Switch, Text, TextLink } from '@blueprints2/core';
import HomeAPI from '../../actions/home';
import { useDispatch, useSelector } from 'react-redux';
import { Store } from '../../store';
import './Home.css';
import { CMP_VALUE, MerchantRecordType, StepStatus } from '../../constants/constants';
import { CCPAStatus, Merchant, MerchantMid, OrderCancellationRequest, ProductFeedRequest } from '../../constants/interfaces';
import OnOffSwitcher from './OnOffSwitcher';
import CustomPopover from '../utils';

interface HomeProps {
	onHttpStatusChange?: Function;
}

const Home = (props: HomeProps) => {
	const { onHttpStatusChange } = props;
	const { statuses } = useSelector((state: Store) => state.home);
	const { merchant } = useSelector((state: Store) => state.merchant);
	// @ts-ignore
	const [merchantState, setMerchantState] = useState({ merchant: undefined as Merchant });
	const [productFeedSwitchState, setProductFeedSwitchState] = useState({ value: false });
	const [ordersCancellationSwitchState, setOrdersCancellationSwitchState] = useState({ value: false });
	const dispatch = useDispatch();

	const [formState, setFormState] = useState({
		ccpaStatus: { status: StepStatus.Incomplete, type: '', withGuide: false } as CCPAStatus,
		spiStatus: StepStatus.Completed,
		additionalScriptStatus: StepStatus.Incomplete,
		pixelTrackingStatus: StepStatus.Incomplete,
		productFeedSyncStatus: StepStatus.Completed,
		isStatusLoaded: false,
		thirdPartyAppStatus: StepStatus.Incomplete,
		orderCancellationStatus: StepStatus.Completed,
		isLoading: false,
		autoCancellationShowPopover: false,
		complianceShowPopover: false,
		productFeedShowPopover: false,
		webPixelEnabled: false,
	});

	const errorCallback = (status: number) => {
		setFormState({ ...formState, isStatusLoaded: true, isLoading: false });
		if (statuses) {
			setProductFeedSwitchState({ value: statuses.productFeedSyncStatus === StepStatus.Completed });
			setOrdersCancellationSwitchState({ value: statuses.orderCancellationStatus === StepStatus.Completed });
		}
		if (onHttpStatusChange) {
			onHttpStatusChange(status);
		}
	};

	const getStatuses = async (mid: string) => {
		await dispatch(HomeAPI.getStatuses(mid, errorCallback));
	};

	const loadingCallback = (isLoading: boolean) => {
		setFormState({ ...formState, isLoading: isLoading });
	};

	const updateProductFeeds = async (productFeedRequest: ProductFeedRequest) => {
		await dispatch(HomeAPI.updateProductFeed(productFeedRequest, loadingCallback, errorCallback));
	};

	const getProductFeedFile = async () => {
		setFormState({ ...formState, isLoading: true });
		await dispatch(HomeAPI.getProductFeedFile(merchantState.merchant.mid, loadingCallback));
	};

	const updateOrderCancellation = async (orderCancellationRequest: OrderCancellationRequest) => {
		await dispatch(HomeAPI.updateOrderCancellation(orderCancellationRequest, loadingCallback, errorCallback));
	};

	const getGuideLink = (cmpType: string) => {
		if (cmpType === CMP_VALUE.iabFramework || cmpType === CMP_VALUE.cookie) {
			return (
				<Text style={{ fontSize: '12px', marginTop: '-1rem' }} className='text-purple-500'>
					<a rel='noopener noreferrer' href='https://rak.app.box.com/s/jbj68qegtumzejee9f9cju2u0a1iwpai' target='_blank'>
						View implementation guide
					</a>
				</Text>
			);
		}
		if (cmpType === 'privacyPolicy') {
			return (
				<Text style={{ fontSize: '12px', marginTop: '-1rem' }} className='text-purple-500'>
					<a rel='noopener noreferrer' href='https://rak.app.box.com/s/r5vh311l1yfcdetbkm1zu6qlf8bqwsb8' target='_blank'>
						View implementation guide
					</a>
				</Text>
			);
		}
		return null;
	};

	const onProductFeedChange = () => {
		const value = !(formState.productFeedSyncStatus === StepStatus.Completed);
		loadingCallback(true);
		const productFeedRequest = {
			productFeedEnabled: !(formState.productFeedSyncStatus === StepStatus.Completed),
			mid: merchantState.merchant.mid,
			recordType: merchantState.merchant.mids.filter((m) => m.mid === merchantState.merchant.mid)[0].recordType,
			imported: merchantState.merchant.imported,
		} as ProductFeedRequest;
		setProductFeedSwitchState({ value: value });
		updateProductFeeds(productFeedRequest);
	};

	const onOrderCancellationChange = () => {
		const value = !(formState.orderCancellationStatus === StepStatus.Completed);
		loadingCallback(true);
		const orderCancellationRequest = {
			orderCancellationEnabled: !(formState.orderCancellationStatus === StepStatus.Completed),
			mid: merchantState.merchant.mid,
			recordType: merchantState.merchant.mids.filter((m) => m.mid === merchantState.merchant.mid)[0].recordType,
			imported: merchantState.merchant.imported,
		} as OrderCancellationRequest;
		setOrdersCancellationSwitchState({ value: value });
		updateOrderCancellation(orderCancellationRequest);
	};

	const getIcon = (status: StepStatus) => {
		switch (status) {
			case StepStatus.Incomplete:
				return (
					<svg height='55' width='90'>
						<circle cx='45' cy='30' stroke='black' strokeWidth='1' r='12' fill='white' />
					</svg>
				);
			case StepStatus.Completed:
				return (
					<>
						<Icon type='checkmark-solid' className='w-6 h-8 text-green-500 mt-4' />
						<Text className='text-green-500 status-text'>Complete</Text>
					</>
				);
			case StepStatus.InReview:
				return (
					<>
						<svg height='50' width='90'>
							<circle cx='45' cy='35' r='12' fill='#ffa002' />
						</svg>
						<Text className='text-orange-500 status-text'>In review</Text>
					</>
				);
			case StepStatus.Failed:
				return (
					<>
						<svg height='50' width='90'>
							<circle cx='45' cy='35' r='12' fill='#d73955' />
						</svg>
						<Text className='text-red-500 status-text'>Failed</Text>
					</>
				);
		}
	};

	const extractThirdPartyAppButton = (): unknown => {
		let pixelButton: unknown;
		if (formState.thirdPartyAppStatus.valueOf() === StepStatus.Incomplete.valueOf()) {
			pixelButton = (
				<span className={formState.isLoading ? 'link-disabled' : ''}>
					<TextLink to='/checkout-apps'>
						<Button isDisabled={formState.isLoading} isSecondary>
							Start
						</Button>
					</TextLink>
				</span>
			);
		} else {
			pixelButton = (
				<span className={formState.isLoading ? 'link-disabled' : ''}>
					<TextLink to='/checkout-apps'>
						<Button isDisabled={formState.isLoading} isSecondary>
							View
						</Button>
					</TextLink>
				</span>
			);
		}
		return pixelButton;
	};

	const extractCCPAButton = (): unknown => {
		let ccpaButton: unknown;
		if (formState.ccpaStatus.status.valueOf() === StepStatus.Completed.valueOf()) {
			ccpaButton = (
				<span className={formState.isLoading ? 'link-disabled' : ''}>
					<TextLink to='/ccpa'>
						<Button isDisabled={formState.isLoading} isSecondary>
							View
						</Button>
					</TextLink>
				</span>
			);
		} else if (formState.ccpaStatus.status.valueOf() === StepStatus.InReview.valueOf()) {
			ccpaButton = (
				<span className={formState.isLoading ? 'link-disabled' : ''}>
					<TextLink to='/ccpa'>
						<Button isDisabled={formState.isLoading} isSecondary>
							Edit
						</Button>
					</TextLink>
				</span>
			);
		} else {
			ccpaButton = (
				<span className={formState.isLoading ? 'link-disabled' : ''}>
					<TextLink to='/ccpa'>
						<Button isDisabled={formState.isLoading} isSecondary>
							Start
						</Button>
					</TextLink>
				</span>
			);
		}
		return ccpaButton;
	};

	const extractPixelTrackingButton = (): unknown => {
		let pixelButton: unknown = '';
		if (
			formState.pixelTrackingStatus.valueOf() === StepStatus.Incomplete.valueOf() ||
			formState.pixelTrackingStatus.valueOf() === StepStatus.Failed.valueOf()
		) {
			pixelButton = (
				<span className={formState.isLoading ? 'link-disabled' : ''}>
					<TextLink to='/pixel'>
						<Button isDisabled={formState.isLoading} isSecondary>
							Start
						</Button>
					</TextLink>
				</span>
			);
		} else {
			pixelButton = (
				<span className={formState.isLoading ? 'link-disabled' : ''}>
					<TextLink to='/pixel'>
						<Button isDisabled={formState.isLoading} isSecondary>
							View
						</Button>
					</TextLink>
				</span>
			);
		}
		return pixelButton;
	};

	const getAutoCancellationPopoverText = () => {
		return (
			<p>
				If you select to enable automatic cancellations, we will use the Shopify Refund web hook to listen for cancellations. When an order
				is cancelled, we check to see which line items in the order were cancelled and then report those back to Rakuten Advertising as
				cancellations using our real-time transaction reporting API.
			</p>
		);
	};

	const getProductFeedPopoverText = () => {
		return (
			<span>
				<p>
					If you choose to enable automatic product feed creation, we will use the Shopify Product API to create a product feed that meets
					Rakuten Advertising’s standards. Your feed will be uploaded to Rakuten Advertising once a day. Please note that the following
					fields are required, and any products that don’t have these values will not be processed: Title, Description, Image and Price.
				</p>
				<p>
					To see an example of the product feed we can create for you based on the Shopify Product API, click the "Download product feed"
					link here.
				</p>
			</span>
		);
	};

	const extractAdditionalButton = (): unknown => {
		let additionalButton;
		if (formState.additionalScriptStatus.valueOf() === StepStatus.Incomplete.valueOf()) {
			additionalButton = (
				<span className={formState.isLoading ? 'link-disabled' : ''}>
					<TextLink to='/tracking'>
						<Button isDisabled={formState.isLoading} isSecondary>
							Start
						</Button>
					</TextLink>
				</span>
			);
		} else if (
			formState.additionalScriptStatus.valueOf ===
			(StepStatus.InReview.valueOf || StepStatus.Failed.valueOf || StepStatus.Completed.valueOf)
		) {
			additionalButton = (
				<span className={formState.isLoading ? 'link-disabled' : ''}>
					<TextLink to='/tracking'>
						<Button isDisabled={formState.isLoading} isSecondary>
							View
						</Button>
					</TextLink>
				</span>
			);
		}
		return additionalButton;
	};

	const getProductFeedMainText = () => {
		return (
			<span>
				Product feed creation and daily upload
				<CustomPopover
					show={formState.productFeedShowPopover}
					className={'popover'}
					position={'right'}
					onShow={() => {
						setFormState({ ...formState, productFeedShowPopover: true });
					}}
					onDismiss={() => {
						setFormState({ ...formState, productFeedShowPopover: false });
					}}
					iconClassName={'text-purple-500 ml-2 w-5 h-8 icon-tooltip'}
					iconType={'information-solid'}
					actionsClassName={'popover-actions-grey'}
				>
					{getProductFeedPopoverText()}
				</CustomPopover>
			</span>
		);
	};

	const renderAutomaticallyCompletedElements = (): JSX.Element => {
		return (
			<Main className='px-10 pt-4' style={{ minHeight: '10rem' }}>
				<Heading level={3}>To get the app installation started, we’ve automatically completed these first steps for you</Heading>
				<hr style={{ marginTop: '7px' }} />
				<Grid>
					<GridCell className='text-center m-auto px-2' key={1} width='1/6'>
						{getIcon(formState.spiStatus)}
					</GridCell>
					<GridCell key={2} width='5/6' className='mt-5 px-2'>
						<Text variation='strong'>Tracking tags install</Text>
						<Text style={{ fontSize: '12px', marginTop: '-1rem' }}>Basic tracking for your store</Text>
					</GridCell>
				</Grid>
				{merchantState.merchant.imported !== undefined && !merchantState.merchant.imported && (
					<span>
						<hr />
						<OnOffSwitcher
							value={productFeedSwitchState.value}
							isLoading={formState.isLoading}
							onChange={onProductFeedChange}
							mainText={getProductFeedMainText()}
							additionalText={
								<>
									<Text style={{ fontSize: '12px', marginTop: '-1rem' }}>You can change this later if required</Text>
									<Text
										style={{ fontSize: '12px', marginTop: '-1rem', cursor: 'pointer' }}
										className={!productFeedSwitchState.value || formState.isLoading ? 'text-gray-500 link-disabled' : 'text-purple-500'}
									>
										<span
											onClick={() => {
												if (productFeedSwitchState.value) {
													getProductFeedFile();
												}
											}}
										>
											<Icon type='download' className={'mr-2'} />
											Download product feed
										</span>
									</Text>
								</>
							}
						/>
					</span>
				)}

				<hr />
				<Grid>
					<GridCell key={1} width='1/6' className='text-center m-auto px-2'>
						<>
							<Icon
								type='checkmark-solid'
								className={ordersCancellationSwitchState.value ? 'w-6 h-8 text-green-500 mt-4' : 'w-6 h-8 text-gray-500 mt-4'}
							/>
							<Text className={ordersCancellationSwitchState.value ? 'text-green-500 status-text' : 'text-gray-500 status-text'}>
								Complete
							</Text>
						</>
					</GridCell>
					<GridCell key={2} width='7/12' className='mt-4 px-2' style={{ margin: 'auto' }}>
						<Text variation='strong'>
							Automatically handle order cancellation
							<CustomPopover
								show={formState.autoCancellationShowPopover}
								className={'popover'}
								position={'right'}
								onShow={() => {
									setFormState({ ...formState, autoCancellationShowPopover: true });
								}}
								onDismiss={() => {
									setFormState({ ...formState, autoCancellationShowPopover: false });
								}}
								iconClassName={'text-purple-500 ml-2 w-5 h-8 icon-tooltip'}
								iconType={'information-solid'}
								actionsClassName={'popover-actions-grey'}
							>
								{getAutoCancellationPopoverText()}
							</CustomPopover>
						</Text>
						<Text style={{ fontSize: '12px', marginTop: '-1rem' }}>You can change this later if required</Text>
					</GridCell>
					<GridCell key={3} width='1/4' className='text-center m-auto'>
						<span style={{ display: 'flex', justifyContent: 'center' }}>
							<Text style={{ marginBottom: 0, marginRight: '-1rem' }}>{ordersCancellationSwitchState.value ? 'On' : 'Off'}</Text>
							<Switch
								isChecked={ordersCancellationSwitchState.value}
								value={ordersCancellationSwitchState.value}
								onChange={() => onOrderCancellationChange()}
								isDisabled={formState.isLoading}
							/>
						</span>
					</GridCell>
				</Grid>
			</Main>
		);
	};

	const renderToDoElements = () => {
		return (
			<Main className='px-10 pt-4 mt-4' style={{ minHeight: '10rem' }}>
				<Heading level={3}>Please complete the following steps (in this order)</Heading>
				<hr style={{ marginTop: '7px' }} />
				{merchantState.merchant.imported !== undefined && !merchantState.merchant.imported && (
					<span>
						<Grid>
							<GridCell key={1} width='1/6' className='text-center m-auto px-2'>
								{getIcon(formState.thirdPartyAppStatus)}
							</GridCell>
							<GridCell key={2} width='7/12' className='mt-4 px-2'>
								<Text variation='strong'>Identify 3rd party apps</Text>
								<Text style={{ fontSize: '11px', marginTop: '-1rem' }}>
									Is your store using a 3rd party app for subscription or checkout?
								</Text>
							</GridCell>
							<GridCell key={3} width='1/4' className='text-center m-auto'>
								{extractThirdPartyAppButton()}
							</GridCell>
						</Grid>

						<hr />

						<Grid>
							<GridCell key={1} width='1/6' className='text-center m-auto px-2'>
								{getIcon(formState.ccpaStatus.status)}
							</GridCell>
							<GridCell key={2} width='7/12' className='mt-5 px-2'>
								<Text variation='strong'>
									Compliance
									<CustomPopover
										show={formState.complianceShowPopover}
										className={'popover'}
										position={'top'}
										onShow={() => {
											setFormState({ ...formState, complianceShowPopover: true });
										}}
										onDismiss={() => {
											setFormState({ ...formState, complianceShowPopover: false });
										}}
										iconClassName={'text-purple-500 ml-2 w-5 h-8 icon-tooltip'}
										iconType={'information-solid'}
										actionsClassName={'popover-actions-grey'}
									>
										{
											<p>
												This step takes care of CCPA compliance only. For GDPR compliance, please work directly with your Rakuten
												representative.
											</p>
										}
									</CustomPopover>
								</Text>
								<Text style={{ fontSize: '12px', marginTop: '-1rem' }}>Does your store comply with CCPA regulations?</Text>
								{formState.ccpaStatus.status.valueOf() === StepStatus.InReview.valueOf() &&
									formState.ccpaStatus.withGuide &&
									getGuideLink(formState.ccpaStatus.type)}
							</GridCell>
							<GridCell key={3} width='1/4' className='text-center m-auto'>
								{extractCCPAButton()}
							</GridCell>
						</Grid>

						<hr />
					</span>
				)}

				{!formState.webPixelEnabled && (
					<Grid>
						<GridCell key={1} width='1/6' className='text-center m-auto px-2'>
							{getIcon(formState.additionalScriptStatus)}
						</GridCell>
						<GridCell key={2} width='7/12' className='mt-5 px-2'>
							<Text variation='strong'>
								Additional script<span style={{ fontWeight: 300 }}> (optional but recommended)</span>
							</Text>
							<Text style={{ fontSize: '12px', marginTop: '-1rem' }}>Allows you to track more data for commissioning and reporting</Text>
						</GridCell>
						<GridCell key={3} width='1/4' className='text-center m-auto'>
							{extractAdditionalButton()}
						</GridCell>
					</Grid>
				)}

				<hr />

				<Grid>
					<GridCell key={1} width='1/6' className='text-center m-auto px-2'>
						{getIcon(formState.pixelTrackingStatus)}
					</GridCell>
					<GridCell key={2} width='7/12' className='mt-4 px-2'>
						<Text variation='strong'>Pixel tracking test</Text>
						<Text style={{ fontSize: '12px', marginTop: '-1rem' }}>Ensures your tracking is working correctly</Text>
					</GridCell>
					<GridCell key={3} width='1/4' className='text-center m-auto'>
						{extractPixelTrackingButton()}
					</GridCell>
				</Grid>
			</Main>
		);
	};

	const renderNonAffiliateToDoElements = () => {
		return (
			<Main className='px-10 pt-4 mt-4' style={{ minHeight: '10rem' }}>
				<Heading level={3}>Please complete the following steps (in this order)</Heading>
				<hr style={{ marginTop: '7px' }} />
				<Grid>
					<GridCell key={1} width='1/6' className='text-center m-auto px-2'>
						{getIcon(formState.thirdPartyAppStatus)}
					</GridCell>
					<GridCell key={2} width='7/12' className='mt-4 px-2'>
						<Text variation='strong'>Identify 3rd party apps</Text>
						<Text style={{ fontSize: '11px', marginTop: '-1rem' }}>Is your store using a 3rd party app for subscription or checkout?</Text>
					</GridCell>
					<GridCell key={3} width='1/4' className='text-center m-auto'>
						{extractThirdPartyAppButton()}
					</GridCell>
				</Grid>

				<hr />

				<Grid>
					<GridCell key={1} width='1/6' className='text-center m-auto px-2'>
						{getIcon(formState.ccpaStatus.status)}
					</GridCell>
					<GridCell key={2} width='7/12' className='mt-5 px-2'>
						<Text variation='strong'>
							Compliance
							<CustomPopover
								show={formState.complianceShowPopover}
								className={'popover'}
								position={'top'}
								onShow={() => {
									setFormState({ ...formState, complianceShowPopover: true });
								}}
								onDismiss={() => {
									setFormState({ ...formState, complianceShowPopover: false });
								}}
								iconClassName={'text-purple-500 ml-2 w-5 h-8 icon-tooltip'}
								iconType={'information-solid'}
								actionsClassName={'popover-actions-grey'}
							>
								<p>
									This step takes care of CCPA compliance only. For GDPR compliance, please work directly with your Rakuten representative.
								</p>
							</CustomPopover>
						</Text>
						<Text style={{ fontSize: '12px', marginTop: '-1rem' }}>Does your store comply with CCPA regulations?</Text>
						{formState.ccpaStatus.status.valueOf() === StepStatus.InReview.valueOf() &&
							formState.ccpaStatus.withGuide &&
							getGuideLink(formState.ccpaStatus.type)}
					</GridCell>
					<GridCell key={3} width='1/4' className='text-center m-auto'>
						{extractCCPAButton()}
					</GridCell>
				</Grid>

				<hr />

				<Grid>
					<GridCell key={1} width='1/6' className='text-center m-auto px-2'>
						{getIcon(formState.additionalScriptStatus)}
					</GridCell>
					<GridCell key={2} width='7/12' className='mt-5 px-2'>
						<Text variation='strong'>
							Additional script<span style={{ fontWeight: 300 }}> (optional but recommended)</span>
						</Text>
						<Text style={{ fontSize: '12px', marginTop: '-1rem' }}>Allows you to track more data for commissioning and reporting</Text>
					</GridCell>
					<GridCell key={3} width='1/4' className='text-center m-auto'>
						{extractAdditionalButton()}
					</GridCell>
				</Grid>
			</Main>
		);
	};

	useEffect(() => {
		if (merchant) {
			setMerchantState({ merchant: merchant });
			getStatuses(merchant.mid);
		}
		if (formState.isStatusLoaded) {
			setFormState({ ...formState, isStatusLoaded: false });
		}
	}, [merchant]);

	useEffect(() => {
		if (statuses) {
			setFormState({ ...statuses });
			setProductFeedSwitchState({ value: statuses.productFeedSyncStatus === StepStatus.Completed });
			setOrdersCancellationSwitchState({ value: statuses.orderCancellationStatus === StepStatus.Completed });
		}
	}, [statuses]);

	useEffect(() => {
		return () => {
			dispatch(HomeAPI.clearState());
		};
	}, []);

	const renderContent = () => {
		if (
			merchantState.merchant.mids &&
			merchantState.merchant.mids.filter((m: MerchantMid) => m.recordType === MerchantRecordType.AFFILIATE).length ===
				merchantState.merchant.mids.length
		) {
			return (
				<>
					{renderAutomaticallyCompletedElements()}
					{renderToDoElements()}
				</>
			);
		}

		if (
			merchantState.merchant.mids &&
			merchantState.merchant.mids.filter((m: MerchantMid) => m.recordType !== MerchantRecordType.AFFILIATE).length ===
				merchantState.merchant.mids.length
		) {
			return (
				<>
					<Main className='px-10 pt-4' style={{ minHeight: '10rem' }}>
						<Heading level={3}>Shopify app installation</Heading>
						<hr style={{ marginTop: '7px' }} />
						<Grid>
							<GridCell className='text-center m-auto px-2' key={1} width='1/6'>
								{getIcon(formState.spiStatus)}
							</GridCell>
							<GridCell key={2} width='5/6' className='mt-5 px-2'>
								<Text variation='strong'>Tracking tags install</Text>
								<Text style={{ fontSize: '12px', marginTop: '-1rem' }}>Basic tracking for your store</Text>
							</GridCell>
						</Grid>
					</Main>
					{renderNonAffiliateToDoElements()}
				</>
			);
		}

		const isNonAffiliateSelected =
			merchantState.merchant.mids &&
			merchantState.merchant.mids.filter(
				(m: MerchantMid) => m.mid === merchantState.merchant.mid && m.recordType === MerchantRecordType.AFFILIATE
			).length === 0;
		if (isNonAffiliateSelected) {
			return (
				<>
					<Main className='px-10 pt-4' style={{ minHeight: '10rem' }}>
						<Heading level={3}>Shopify app installation</Heading>
						<hr style={{ marginTop: '7px' }} />
						<Grid>
							<GridCell className='text-center m-auto px-2' key={1} width='1/6'>
								{getIcon(formState.spiStatus)}
							</GridCell>
							<GridCell key={2} width='5/6' className='mt-5 px-2'>
								<Text variation='strong'>Tracking tags install</Text>
								<Text style={{ fontSize: '12px', marginTop: '-1rem' }}>Basic tracking for your store</Text>
							</GridCell>
						</Grid>
					</Main>
				</>
			);
		} else {
			return (
				<>
					{renderAutomaticallyCompletedElements()}
					{renderToDoElements()}
				</>
			);
		}
	};

	if (!formState.isStatusLoaded) {
		return <Spinner />;
	}

	return (
		<>
			{renderContent()}
			<Main className='px-10 pt-4' style={{ backgroundColor: 'transparent', display: 'table' }}>
				<a style={{ fontSize: 12, color: '#7141d8' }} rel='noopener noreferrer' href='https://dashboard.linkshare.com/' target='_blank'>
					Go to the Rakuten Advertiser Dashboard to continue onboarding steps
				</a>
			</Main>
		</>
	);
};

export default Home;
