import React, { useContext, useEffect, useState } from 'react';
import { Spinner } from '../common';
import { useDispatch, useSelector } from 'react-redux';
import { CALLBACK, HOME, HOST_KEY, SHOP } from '../../constants/constants';
import { AUTH_DATA_ADD } from '../../constants/action-types';
import AppHomeAPI from '../../actions/app-home';
import moment from 'moment';
import { Store } from '../../store';
import { Redirect, withRouter } from 'react-router-dom';
import { AuthContext } from '../../utils/AuthProvider';

const AppHome = (props: any) => {
	const { shopOriginValue, hostValue } = useContext(AuthContext);
	const { redirectLocation } = useSelector((state: Store) => state.appHome);
	const [state, setState] = useState({ redirectLocation: '' });
	const { REACT_APP_API_KEY } = process.env;
	const dispatch = useDispatch();

	let shop = shopOriginValue;
	let host = hostValue;
	if (props.location && props.location.search) {
		if (!shopOriginValue) {
			const shopParam = new URLSearchParams(props.location.search).get(SHOP);
			if (shopParam) {
				shop = shopParam;
			}
		}
		const hostParam = new URLSearchParams(props.location.search).get(HOST_KEY);
		if (hostParam) {
			host = hostParam;
		}
	}

	const storeShopData = async () => {
		await dispatch({ type: AUTH_DATA_ADD, data: { shopOrigin: shop, host: host } });
	};

	const getAppResponse = async (shop: string) => {
		await dispatch(AppHomeAPI.getAppResponse(shop));
	};

	useEffect(() => {
		storeShopData().then(() => {
			// sessionStorage.setItem(SHOP_ORIGIN_KEY, shopWrapper[0]);
			// sessionStorage.setItem(HOST_KEY, hostWrapper[0]);
		});
		getAppResponse(shop as string);
		return () => {
			dispatch(AppHomeAPI.clearState());
		};
	}, []);

	useEffect(() => {
		setState({ redirectLocation: redirectLocation });
	}, [redirectLocation]);

	if (state.redirectLocation === '') {
		return <Spinner />;
	}

	if (state.redirectLocation === HOME) {
		return <Redirect to={{ pathname: `/${HOME}` }} />;
	}

	if (state.redirectLocation === CALLBACK) {
		const time = moment().valueOf();
		const pathName = `/shopify/appbridge/read_products,read_script_tags,write_script_tags,read_orders,read_customer_events,write_pixels,read_pixels,read_customers/${REACT_APP_API_KEY}/${shop}/${time}`;
		return <Redirect to={{ pathname: pathName }} />;
	}

	return <Redirect to={{ pathname: '/error/unauthorized' }} />;
};

export default withRouter(AppHome);
