import { Dispatch } from 'redux';
import ShopifyAPi from '../http/shopify';
import {
	HOME_ORDER_CANCELLATION_UPDATE,
	HOME_PRODUCT_FEED_UPDATE,
	HOME_STATUSES_CLEAR,
	HOME_STATUSES_GET,
} from '../constants/action-types';
import { API } from '../constants/api';
import { OrderCancellationRequest, ProductFeedRequest } from '../constants/interfaces';
import { StepStatus } from '../constants/constants';
import { processFile } from '../constants/functions';

export default {
	getStatuses: (mid: string, errorCallback: Function) => async (dispatch: Dispatch) => {
		try {
			const response = await ShopifyAPi.get(`${API.V1.shopify.onboarding.status}?mid=${mid}`);
			dispatch({ type: HOME_STATUSES_GET, data: { ...response.data, isStatusLoaded: true } });
		} catch (err: any) {
			console.log('Error while getting onboarding status: ' + err.response.data.message);
			errorCallback(err.response.status);
		}
	},
	updateProductFeed:
		(productFeedRequest: ProductFeedRequest, loadingCallback: Function, errorCallback: Function) => async (dispatch: Dispatch) => {
			try {
				const response = await ShopifyAPi.post(API.V1.configuration.productFeed, productFeedRequest);
				dispatch({
					type: HOME_PRODUCT_FEED_UPDATE,
					data: { productFeedSyncStatus: response.data.productFeedEnabled ? StepStatus.Completed : StepStatus.Incomplete },
				});
				loadingCallback(false);
			} catch (err: any) {
				console.log('Error while updating product feed: ' + err.response.data.message);
				errorCallback(err.response.status);
			}
		},
	getProductFeedFile: (mid: string, loadingCallback: Function) => async (dispatch: Dispatch) => {
		try {
			const response = await ShopifyAPi.get(`${API.V1.product.file}?mid=${mid}`);
			let fileName = null;
			if (response.status === 204) {
				console.log('There is no data');
			} else {
				const disposition = response.headers['content-disposition'];
				if (disposition && disposition.indexOf('attachment') !== -1) {
					const matches = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/.exec(disposition);
					if (matches != null && matches[1]) {
						fileName = matches[1].replace(/['"]/g, '');
					}
				}
				await processFile(response.data, fileName, response.headers['content-type']);
			}
		} catch (e) {
			console.error(e);
		} finally {
			loadingCallback(false);
		}
	},
	updateOrderCancellation:
		(orderCancellationRequest: OrderCancellationRequest, loadingCallback: Function, errorCallback: Function) =>
		async (dispatch: Dispatch) => {
			try {
				const response = await ShopifyAPi.post(API.V1.configuration.orderCancellation, orderCancellationRequest);
				dispatch({
					type: HOME_ORDER_CANCELLATION_UPDATE,
					data: { orderCancellationStatus: response.data.orderCancellationEnabled ? StepStatus.Completed : StepStatus.Incomplete },
				});
				loadingCallback(false);
			} catch (err: any) {
				console.log('Error while updating order cancellation: ' + err.response.data.message);
				errorCallback(err.response.status);
			}
		},
	clearState: () => (dispatch: Dispatch) => dispatch({ type: HOME_STATUSES_CLEAR }),
};
