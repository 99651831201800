import { API } from './api';

export const SHOP_ORIGIN_KEY = 'shopOrigin';
export const HOST_KEY = 'host';
export const UNAUTHORIZED = 'Unauthorized';
export const HOME = 'home';
export const CALLBACK = 'callback';
export const SHOP = 'shop';

export const NO_AUTH_URLS = [API.V1.shopifyApp.appHome, API.V1.shopifyApp.callBack];

export enum ThirdPartyStatus {
	COMPLETED = 1,
	IN_COMPLETE = 0,
}

export enum StepStatus {
	Incomplete = 0,
	Completed = 1,
	InReview = 2,
	Failed,
}

export enum UserConsentSelection {
	CMPPlatform,
	CookieConsent,
	JustUpdatedPrivacyPolicy,
	NotSure,
	DontHave,
}

export const CMP_VALUE = {
	iabFramework: 'iabFramework',
	cookie: 'cookieBanner',
};

export enum MerchantRecordType {
	AFFILIATE = 'AFFILIATE',
	DISPLAY = 'DISPLAY',
	SEARCH = 'SEARCH',
}
